/**
 * This CSS file still exists because the expression builder requires some class nesting and
 * interdependencies that I still haven't figured out how to do in JSS.  Examples include:
 *
 * .classA .classA { ...styles applied when a class is a child of itself... }
 *
 * .classB { ...classB on its own... }
 * .classC .classB { ...classB as a child of classC }
 */
.group-container {
    background-color: transparent;
    width: 100%;
    position: relative;
    min-height: 120px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.group-op {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #F0F0F0 !important;
    width: 150px;
    display: flex;
    flex-direction: column;
}

.condition-children {
    margin-left: 150px;
    position: static;
    box-sizing: border-box;
}

.condition-children .group-op:before {
    content: "";
    display: inline-block;
    margin-left: -20px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-bottom: #aaa 2px solid;
    background-color: transparent;
}

.condition-children .group-op {
    margin: 0 0 0 20px;
}

.condition-children .group-box-controls {
    margin-top: -20px;
}

.condition-children .condition-children {
    margin-left: 170px;
}

.expression-row {
    min-width: 320px;
    margin: 10px 10px 10px 20px;
}

.expression-row:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: -20px;
    width: 20px;
    height: 2px;
    border: #aaa 1px solid;
    background-color: transparent;
}

.expression-box {
    background-color: white;
    border-radius: 2px;
    padding: 8px;
    height: 100%;
    min-width: 320px;
    display: inline-block;
    box-shadow:
        0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
